div.nav-drawer {
  height: 100%;
  width: 350px;
  background-image: url(./menu-bg.jpg);
}

.nav-clickable {
  cursor: pointer;
}

div.nav-inner {
  padding: 35px 0 0 35px;
}

div.nav-close-button {
  margin-bottom: 60px;
}

div.nav-search {
  padding: 0px 0px 0px 19.5px;
}

input.nav-search-text {
  font-size: 14px;
  background-color: #eaeae8;
  float: left;
  width: 207.5px;
  height: 44px;
  padding: 0 0 0 15px;
  border-radius: 10px 0 0 10px;
  border: 0;
}

input.nav-search-submit {
  background-color: #eaeae8;
  background-image: url(./search-icon.png);
  background-repeat: no-repeat;
  background-position: center center;
  border: 0 none;
  height: 44px;
  width: 41px;
  font-size: 0;
  padding: 0;
  margin: 0;
  border-radius: 0 10px 10px 0;
}

div.nav-menu {
  padding: 30px 0px 0px 29.5px;
}

div.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

div.nav-menu ul a {
  color: #00a8e2;
  display: block;
  font-family: 'freightsans_prosemibold';
  font-size: 21px;
  margin: 20px 0;
  text-decoration: none;
}

.nav-high-contrast div.nav-menu ul a {
  color: #005db9;
}

.nav-high-contrast-toggle {
  cursor: pointer;
  padding: 0px 0px 0px 29.5px;
  margin: 39px 0px 0px 0px;
}

.nav-high-contrast-toggle span {
  line-height: 28px;
  padding-left: 3px;
  font-size: 16px;
}

.nav-high-contrast-icon {
  float: left;
  margin-right: 5px;
  width: 28px;
  height: 28px;
  background-image: url(./high-contrast-off.png);
  background-size: cover;
}

.nav-high-contrast-icon:hover {
  background-image: url(./high-contrast-off-hover.png);
}

.nav-high-contrast .nav-high-contrast-icon {
  background-image: url(./high-contrast-on.png);
}

.nav-high-contrast .nav-high-contrast-icon:hover {
  background-image: url(./high-contrast-on-hover.png);
}

@media screen and (max-width: 650px) {
  div.nav-drawer {
    background-image: none;
    background-color: #fff;
    width: 100%;
  }

  div.nav-inner {
    padding: 0px;
  }

  div.nav-search {
    padding: 30px 30px 0px 30px;
  }

  div.nav-menu {
    padding: 15px 30px;
  }

  input.nav-search-text {
    width: 80%;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
  }
  input.nav-search-submit {
    width: 20%;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
  }

  div.nav-close-button {
    text-align: right;
    margin: 20px;
  }

  div.nav-close-button img {
    width: 16px;
  }

  div.nav-high-contrast-toggle {
    padding: 0px;
    margin: 0px 0px 0px 50px;
  }
}

@media screen and (max-width: 1025px) {
  div.nav-inner {
    padding: 15px 0 0 15px;
  }

  div.nav-search {
    padding: 30px 30px 0px 10px;
  }

  div.nav-menu {
    padding: 15px 10px;
  }

  .nav-high-contrast-toggle {
    cursor: pointer;
    padding: 0px 0px 0px 34.5px;
    margin: 10px 0px 0px 0px;
  }

  input.nav-search-submit {
    width: 37px;
  }
}

@media screen and (max-width: 980px) {
  div.nav-inner {
    padding: 15px 0 0 30px;
  }

  div.nav-close-button {
    margin-bottom: 30px;
  }

  div.nav-search {
    padding: 0px 30px 0px 0px;
  }

  div.nav-menu {
    padding: 15px 10px 15px 2px;
  }

  input.nav-search-submit {
    width: 45px;
  }

  div.nav-menu ul li a:after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background-image: url(./arrow-right.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    float: right;
    margin-right: 20px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 766px) {
  div.nav-inner {
    padding: 15px 0 0 30px;
  }

  div.nav-close-button {
    text-align: right;
    margin: -5px 20px 58px 0;
    padding: 10px;
  }

  div.nav-close-button img {
    height: 18px;
  }

  input.nav-search-text {
    width: 225px;
    border-radius: 0px;
  }

  input.nav-search-submit {
    width: 41px;
    border-radius: 0px;
  }

  div.nav-menu ul li a:after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background-image: url(./arrow-right.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    float: right;
    margin-right: 20px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 650px) {
  div.nav-inner {
    padding: 15px 0 0 30px;
  }

  div.nav-close-button {
    text-align: right;
    margin: -5px 20px 58px 0;
    padding: 10px;
  }

  div.nav-close-button img {
    height: 18px;
  }

  div.nav-search {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }

  input.nav-search-text {
    width: 80%;
    border-radius: 0px;
    padding: 0px 0px 0px 15px;
  }

  input.nav-search-submit {
    width: 12%;
    border-radius: 0px;
  }

  div.nav-menu ul li a:after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background-image: url(./arrow-right.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    float: right;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 420px) {
  div.nav-inner {
    padding: 15px 30px 0 30px;
  }

  div.nav-close-button {
    text-align: right;
    margin: -5px 20px 58px 0;
    padding: 10px;
  }

  div.nav-close-button img {
    height: 18px;
  }

  div.nav-search {
    margin: 0px;
    padding: 0px;
    width: 100%;
  }

  input.nav-search-text {
    width: 80%;
    border-radius: 0px;
    padding: 0px 0px 0px 0px;
    font-size: 14px;
    text-indent: 15px;
  }

  input.nav-search-submit {
    width: 20%;
    border-radius: 0px;
  }

  div.nav-menu {
    padding-left: 0px;
  }

  div.nav-menu ul li a:after {
    content: "";
    width: 20px;
    height: 20px;
    display: block;
    background-image: url(./arrow-right.png);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    float: right;
    margin-right: -15px;
    margin-top: 5px;
  }
}
