html, body {
  font-size: 16px;
  font-family: 'freightsans_probook';
  height: 100%;
  margin: 0px;
  padding: 0px;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

h1 {
  font-size: 48px;
  color: #005db9;
  line-height: 48px;
}

h2 {
  font-size: 36px;
  color: #005db9;
  line-height: 40px;
}

h3 {
  font-size: 28px;
  color: #005db9;
  line-height: 28px;
}

h4 {
  font-size: 20px;
  color: #00a8e2;
  margin-bottom: 10px;
}

h5 {
  font-size: 18px;
  color: #e64d39;
  margin-bottom: 5px;
}

h6 {
  font-size: 18px;
  color: #333;
}

a {
  color: #14a7e0;
  text-decoration: none;
}

a:hover {
  color: #c72d19;
  text-decoration: none;
}

a:focus {
  color: #c72d19;
  text-decoration: none;
}

p {
  font-size: 16px;
  color: #333;
  line-height: 22px;
  margin: 0 0 20px;
}

p.introduction {
  font-size: 24px;
  color: #14a7e0;
  line-height: 32px;
}

div.error {
  font-size: 20px;
  line-height: 24px;
  background-color: rgb(227, 78, 53) !important;
  color: #fff;
}

div.error a {
  color: rgb(238, 234, 157);
  color: rgb(237, 194, 0);
}

nav.inner-navigation ul {
  list-style: outside none none;
  padding: 0;
}

nav.inner-navigation li.expanded > span {
  font-size: 28px;
  color: #225fad;
  padding: 10px 0 10px 20px;
  border-top: 2px solid #225fad;
  display: block;
}

nav.inner-navigation li.expanded .level2 {
  background-color: #f4f4f5;
  border-top: 1px solid #dad9d7;
  border-bottom: 1px solid #dad9d7;
  display: block;
  margin-bottom: 30px;
  padding: 10px 10px 10px 0;
}

nav.inner-navigation li.expanded .level2 li a:before {
  content: "\232A";
  font-size: 14px;
  padding-right: 6px;
}

nav.inner-navigation li.expanded .level2 li a {
  color: #005db9;
  text-decoration: none;
  font-weight: bold;
}

nav.inner-navigation li.expanded .level2 li {
  display: block;
  padding: 6px 0 6px 20px;
}

#root {
  height: 100%;
}

div.page-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

div.page-menu {
  position: fixed;
  left: -350px;
  transition-property: left;
  transition-duration: 0.5s;
  transition-delay: 0s;
  height: 100%;
}

div.page-menu-visible {
  left: 0px;
  transition-property: left;
  transition-duration: 0.5s;
  transition-delay: 0s;
}

div.page-content {
  flex-grow: 1;
  position: relative;
  left: 0px;
  transition-property: left;
  transition-duration: 0.5s;
  transition-delay: 0s;
  overflow: auto;
}

div.page-content-shifted {
  left: 350px;
  position: relative;
  transition-property: left;
  transition-duration: 0.5s;
  transition-delay: 0s;
  overflow: hidden;
}

div.page-content-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

div.page-content-container {
  padding: 0px 100px 0px 105px;
}

div.MuiPaper-root {
  margin: 10px 0px;
}

h1 {
  padding: 0px;
  margin: 0px 0px 20px;
}

h2 {
  padding: 0px;
  margin: 0px 0px 20px;
}

h3 {
  padding: 0px;
  margin: 0px 0px 20px;
}

@media screen and (max-width: 1060px) {
  div.page-content-container {
    margin: 0 auto;
  }
}

@media screen and (max-width: 1025px) {
  div.page-content-container {
    padding: 8px 40px 20px;
  }

  h1 {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
  }

  h2 {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
  }

  h3 {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
  }
}

@media screen and (max-width: 980px) {
  div.page-content-container {
    padding: 25px;
  }

  h1 {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
  }

  h2 {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
  }

  h3 {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
  }
}

@media screen and (max-width: 766px) {
  div.page-content-container {
    padding: 0px 15px 15px 15px;
    overflow-x: hidden;
  }

  h1 {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
    font-size: 32px;
    line-height: 32px;
  }

  h2 {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
    font-size: 26px;
    line-height: 29px;
  }

  h3 {
    padding: 0px 0px 10px 0px;
    margin: 0px 0px 10px 0px;
    font-size: 22px;
    line-height: 22px;
  }
}

@media screen and (max-width: 650px) {
}

div.page-high-contrast h1 {
  color: #005db9;
}

div.page-high-contrast h2 {
  color: #005db9;
}

div.page-high-contrast h3 {
  color: #005db9;
}

div.page-high-contrast h4 {
  color: #005db9;
}

div.page-high-contrast h5 {
  color: #005db9;
}

div.page-high-contrast h6 {
  color: #005db9;
}

div.page-high-contrast a {
  color: #005db9;
}
