html, body {
  padding: 0em;
  margin: 0em;
}

@font-face {
    font-family: 'freightsans_probold';
    src: url('./fonts/freigsanprobold-webfont.eot');
    src: url('./fonts/freigsanprobold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/freigsanprobold-webfont.woff2') format('woff2'),
         url('./fonts/freigsanprobold-webfont.woff') format('woff'),
         url('./fonts/freigsanprobold-webfont.ttf') format('truetype'),
         url('./fonts/freigsanprobold-webfont.svg#freightsans_probold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'freightsans_probook';
    src: url('./fonts/freigsanprobook-webfont.eot');
    src: url('./fonts/freigsanprobook-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/freigsanprobook-webfont.woff2') format('woff2'),
         url('./fonts/freigsanprobook-webfont.woff') format('woff'),
         url('./fonts/freigsanprobook-webfont.ttf') format('truetype'),
         url('./fonts/freigsanprobook-webfont.svg#freightsans_probook') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'freightsans_promedium';
    src: url('./fonts/freigsanpromed-webfont.eot');
    src: url('./fonts/freigsanpromed-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/freigsanpromed-webfont.woff2') format('woff2'),
         url('./fonts/freigsanpromed-webfont.woff') format('woff'),
         url('./fonts/freigsanpromed-webfont.ttf') format('truetype'),
         url('./fonts/freigsanpromed-webfont.svg#freightsans_promedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'freightsans_prosemibold';
    src: url('./fonts/freigsanprosem-webfont.eot');
    src: url('./fonts/freigsanprosem-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/freigsanprosem-webfont.woff2') format('woff2'),
         url('./fonts/freigsanprosem-webfont.woff') format('woff'),
         url('./fonts/freigsanprosem-webfont.ttf') format('truetype'),
         url('./fonts/freigsanprosem-webfont.svg#freightsans_prosemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'freightsans_probook-oldstyle';
    src: url('./fonts/freigsanprobook-oldstyle.eot');
    src: url('./fonts/freigsanprobook-oldstyle.eot?#iefix') format('embedded-opentype'),
         url('./fonts/freigsanprobook-oldstyle.woff2') format('woff2'),
         url('./fonts/freigsanprobook-oldstyle.woff') format('woff'),
         url('./fonts/freigsanprobook-oldstyle.ttf') format('truetype'),
         url('./fonts/freigsanprobook-oldstyle.svg#freightsans_probook-oldstyle') format('svg');
    font-weight: normal;
    font-style: normal;
}
