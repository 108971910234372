div.qualify-form-container {
  text-align: center;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  background-color: rgb(227, 78, 53);
}

div.qualify-form-container a {
  color: #fff;
  text-decoration: none;
}
