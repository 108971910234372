div.nav-footer-container {
  background-color: #333;
  color: #ccc;
  padding: 20px 0;
  margin-top: 20px;
}

div.nav-footer-inner {
  width: auto;
  margin: 0 auto;
  padding: 0 70px;
  font-size: 16px;
}

div.nav-footer-inner a.nav-footer-copyright {
  background-image: url(./footer-link-divider.jpg);
  background-position: 12px 3px;
  background-repeat: no-repeat;
  color: #ccc;
  font-size: 14px;
  padding-left: 30px;
  text-decoration: none;
}

@media screen and (max-width: 766px) {
  div.nav-footer-inner {
    padding: 0px;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  div.nav-footer-container {
    width: 100%;
  }

  div.nav-footer-inner span {
    text-align: center;
    display: block;
  }
}
