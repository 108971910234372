div.nav-header-container {
  padding: 20px 40px;
}

div.nav-header {
  display: flex;
  flex-direction: row;
}

div.nav-header-menu-toggle {
  width: 90px;
  position: absolute;
  cursor: pointer;
  display: none;
}

div.nav-header-menu-visible {
  display: block;
}

div.nav-header-menu-bars {
  height: 12px;
  width: 34px;
  float: left;
  padding-right: 10px;
  cursor: pointer;
}

div.nav-header-menu-bars span.icon-bar {
  border-radius: 0;
  display: block;
  height: 3px;
  width: 30px;
  background-color: #005cb9;
  margin: 4px;
}

div.nav-header-menu-text {
  color: #005db9;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

div.nav-header-logo {
  align-self: center;
  text-align: center;
  width: 30.75%;
}

div.nav-header-logo img {
  vertical-align: middle;
}

div.nav-header-image {
  flex-grow: 1;
}

div.nav-header-image img {
  float: right;
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 246px;
}

div.nav-breadcrumbs-container {
  display: flex;
  margin-top: 20px;
}

div.nav-breadcrumbs {
  background-color: #dcd8ce;
  padding: 9px 0px 9px 70px;
  margin-right: 20px;
  color: #000;
  flex-grow: 1;
}

div.nav-breadcrumbs a {
  color: #000;
  text-decoration: underline;
  font-size: 16px;
}

div.nav-breadcrumbs-links {
  width: 40px;
  height: 41px;
}

span.nav-breadcrumb-current {
  font-size: 16px;
  text-decoration: underline;
}

div.nav-breadcrumbs-links img {
  width: 40px;
}

div.nav-breadcrumbs-links-popper {
  padding: 20px;
  background-color: #00a8e2;
}

span.nav-breadcrumbs-links-popper-title {
  color: #fff;
  font-family: "freightsans_probold";
  font-size: 20px;
}

ul.nav-breadcrumbs-links-popper-list {
  margin: 0;
  padding: 0;
  list-style: disc;
  list-style-position: inside;
}

ul.nav-breadcrumbs-links-popper-list li {
  color: #fff;
}

ul.nav-breadcrumbs-links-popper-list li a {
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 1310px) {
  div.nav-header-logo {
    width: 35%;
  }
  div.nav-header-image img {
    max-width: 98%;
  }
}

@media screen and (max-width: 1215px) {
  div.nav-header-logo {
    width: 48%;
  }
  div.nav-header-image img {
    max-width: 100%;
  }
}

@media screen and (max-width: 1115px) {
  div.nav-header-logo {
    width: 65%;
  }
  div.nav-header-image img {
    max-width: 107%;
  }
}

@media screen and (max-width: 1024px) {
  div.nav-header-logo {
    width: 50%;
  }
  div.nav-header-logo img {
    width: 134px;
  }
  div.nav-header-image img {
    max-width: 98%;
  }
  div.nav-breadcrumbs {
    padding: 9px 0px 9px 15px;
  }
}

@media screen and (max-width: 1015px) {
  div.nav-header-logo {
    width: 50%;
  }
  div.nav-header-logo img {
    padding-left: 85px;
    width: 134px;
  }
  div.nav-header-image img {
    max-width: 85%;
  }
}

@media screen and (max-width: 980px) {
  div.nav-header-container {
    padding: 20px 25px 0;
  }

  div.nav-header-logo {
  }
  div.nav-header-logo img {
    margin-left: -5px;
    width: 134px;
  }
  div.nav-header-image img {
    max-width: 93.0%;
  }
}

@media screen and (max-width: 766px) {
  div.nav-header {
    display: block;
  }
  div.nav-header-container {
    padding: 15px;
  }

  div.nav-header-menu-toggle {
    position: relative;
    float: right;
  }

  div.nav-header-logo {
    padding: 45px 0px 20px 0px;
    margin: 0px;
    width: 100%;
  }
  div.nav-header-logo img {
    padding: 0px;
    margin: 0px;
    width: 120px;
  }

  div.nav-header-image img {
    float: none;
    max-width: 100%;
  }

  div.nav-breadcrumbs-container {
    display: none;
  }
}
